<template>
  <div class="my-multi-table">
    <div class="button_group">
      <div style="margin-left:60px;">
        筛选条件：<el-cascader
          ref="cascader"
          class="cascader-box"
          v-model="queryId"
          :options="treeList"
          :props="{
            expandTrigger: 'hover',
            value: 'tagId',
            label: 'name',
            emitPath: false,
            checkStrictly: true
          }"
          :show-all-levels="false"
          clearable
          @change="changeFn"
        >
          <template slot-scope="{ node, data }" class="cascader-item">
            <span class="select-item" @click="handleSelect">{{
              data.name
            }}</span>
          </template>
        </el-cascader>
      </div>

      <el-button type="primary" @click="printSendTable">打印单据</el-button>
    </div>
    <div v-if="document.xAdmin" class="print" ref="print">
      <div style="margin:0 auto;">
        <div
          style="color: #666666; text-align:center;margin-top:30px; margin-bottom: 10px; display: flex; justify-content: space-between;"
        >
          <div>顺猴出行商家票据</div>
          <div>日期：{{ new Date().toLocaleDateString() }}</div>
        </div>
        <h3 style="text-align:center;margin-top:30px; margin-bottom: 10px">
          班次订单统计详细票据
        </h3>
        <div style="text-align:right; margin-bottom: 10px">
          编号：_________________
        </div>
        <table
          style="width: 100%"
          class="table table-striped table-bordered"
          align="center"
          valign="center"
        >
          <tr>
            <td class="column" colspan="8">基本信息</td>
          </tr>
          <tr>
            <td class="column" colspan="2">入驻商家账号</td>
            <td class="value" colspan="6">{{ document.xAdmin.username }}</td>
          </tr>
          <tr>
            <td class="column" colspan="2">电话/手机</td>
            <td class="value" colspan="3">{{ document.xAdmin.userPhone }}</td>
            <td class="column">邮箱</td>
            <td class="value" colspan="2">{{ document.xAdmin.email }}</td>
          </tr>
          <tr>
            <td class="column" colspan="2">账户状态</td>
            <td class="value" colspan="3">
              {{ document.xAdmin.status ? '开启' : '禁用' }}
            </td>
            <td class="column">扫码状态</td>
            <td class="value" colspan="3">
              {{ document.xAdmin.wxstatus ? '已绑定' : '未绑定' }}
            </td>
          </tr>
          <!-- <tr>
            <td class="column" colspan="2">客户PO号码</td>
            <td class="value" colspan="2"></td>
            <td class="column" colspan="1">发货仓库</td>
            <td class="value" colspan="3"></td>
          </tr> -->
          <tr>
            <td class="column" colspan="8">详细信息</td>
          </tr>
          <tr>
            <td class="column" colspan="2">筛选单位(班次/日期/标签)</td>
            <td v-if="type === 1" class="value" colspan="2">班次</td>
            <td v-if="type === 2" class="value" colspan="2">日期</td>
            <td v-if="type === 3" class="value" colspan="2">标签</td>
            <td class="column" colspan="2">订单总数</td>
            <td class="value" colspan="2">{{ document.document[0].total }}</td>
          </tr>
          <tr>
            <td class="column" colspan="2">班次</td>
            <td class="value" colspan="6"></td>
          </tr>
          <tr>
            <td class="column" colspan="2">日期</td>
            <td class="value" colspan="6"></td>
          </tr>
          <tr>
            <td class="column" colspan="2">标签</td>
            <td class="value" colspan="6"></td>
          </tr>
          <tr>
            <td class="column" colspan="2">已支付订单数</td>
            <td class="value" colspan="2">
              {{ document.document[0].payCount }}
            </td>
            <td class="column" colspan="2">已退款订单数</td>
            <td class="value" colspan="2">
              {{ document.document[0].refundCount }}
            </td>
          </tr>
          <tr>
            <td class="column" colspan="2">未付款订单数</td>
            <td class="value" colspan="2">
              {{ document.document[0].unPayCount }}
            </td>
            <td class="column" colspan="2">总票数</td>
            <td class="value" colspan="2">{{ document.document[0].total }}</td>
          </tr>
          <tr>
            <td class="column" colspan="2">已支付票数</td>
            <td class="value" colspan="2">{{ document.document[0].paySum }}</td>
            <td class="column" colspan="2">已退款票数</td>
            <td class="value" colspan="2">
              {{ document.document[0].refundPaySum }}
            </td>
          </tr>
          <tr>
            <td class="column" colspan="2">未支付票数</td>
            <td class="value" colspan="2">
              {{ document.document[0].unPaySum }}
            </td>
            <td class="column" colspan="2">已支付总金额</td>
            <td class="value" colspan="2" style="font-weight:bold;">
              {{ document.document[0].orderPriceSum }}
            </td>
          </tr>
          <tr>
            <td class="column" colspan="2">已退款总金额</td>
            <td class="value" colspan="2">
              {{ document.document[0].totalRefund }}
            </td>
            <td class="column" colspan="2">退款手续费总额</td>
            <td class="value" colspan="2" style="font-weight:bold;">{{ document.document[0].sxf }}</td>
          </tr>
          <tr>
            <td class="column" colspan="2">总金额(已支付总金额)</td>
            <td class="value" colspan="2"></td>
            <td class="column" colspan="2">退款手续费+已支付</td>
            <td class="value" colspan="2"></td>
          </tr>
          <tr>
            <td class="value" colspan="8" style="text-align: left">备注：</td>
          </tr>
          <tr>
            <td class="column" colspan="8">订单详细（已支付）</td>
          </tr>
          <tr>
            <td class="column">订单编号</td>
            <td class="column">姓名</td>
            <td class="column">班次</td>
            <td class="column">出发日期</td>
            <td class="column">标签</td>
            <td class="column">车牌号</td>
            <td class="column">原因</td>
            <td class="column">下单时间</td>
          </tr>
          <tr v-for="item in orders" :key="item.orderNumber">
            <td class="value" colspan="1">{{item.orderNumber}}</td>
            <td class="value" colspan="1">{{item.username}}</td>
            <td class="value" colspan="1"></td>
            <td class="value" colspan="1">{{item.departureTime}}</td>
            <td class="value" colspan="1">{{item.tagName}}</td>
            <td class="value" colspan="1">{{item.carCard}}</td>
            <td class="value" colspan="1" v-if="item.payStatus==0">未付款</td>
            <td class="value" colspan="1" v-if="item.payStatus==1">已付款</td>
            <td class="value" colspan="1" v-if="item.payStatus==2">待退款</td>
            <td class="value" colspan="1" v-if="item.payStatus==3">未退款</td>
            <td class="value" colspan="1" v-if="item.payStatus==4">已退款</td>
            <td class="value" colspan="1" v-if="item.payStatus==5">部分退款</td>
            <td class="value" colspan="1">{{item.createTime}}</td>
          </tr>
          <tr>
            <td class="value" colspan="8">
              <div style="display: flex; flex-direction: column;">
                <div style="display: flex; justify-content: flex-start;">
                  备注：
                </div>
                <div
                  style="display: flex; justify-content: flex-end; margin-top: 20px"
                >
                  日期/时间：__________________
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td class="column" colspan="8">订单详细（已退款）</td>
          </tr>
          <tr>
            <td class="column">订单编号</td>
            <td class="column">退款订单号</td>
            <td class="column">订单总金额</td>
            <td class="column">手续费</td>
            <td class="column">退款金额</td>
            <td class="column">距离小时</td>
            <td class="column">是否退款</td>
            <td class="column">下单时间</td>
          </tr>
          <tr v-for="item in refundList" :key="item.outTradeNo">
            <td class="value" colspan="1">{{item.outTradeNo}}</td>
            <td class="value" colspan="1">{{item.outRefundNo}}</td>
            <td class="value" colspan="1">{{item.refundFee}}</td>
            <td class="value" colspan="1">{{item.procedureFee}}</td>
            <td class="value" colspan="1">{{item.refundSum}}</td>
            <td class="value" colspan="1">{{item.hour}}</td>
            <td class="value" colspan="1">{{item.status==true?"已退款":"未退款"}}</td>
            <td class="value" colspan="1">{{item.createTime}}</td>
          </tr>
          <!-- <tr>
            <td class="column" colspan="8">收货信息（收货人填写）</td>
          </tr>
          <tr>
            <td class="column" colspan="2">到达签收时间</td>
            <td class="value" colspan="2">____年__月__日__时__分</td>
            <td class="column" colspan="2">到达时温度</td>
            <td class="value" colspan="2">℃</td>
          </tr>
          <tr>
            <td class="column" colspan="2">运输是否超温</td>
            <td class="value" colspan="2">
              __是&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;__否
            </td>
            <td class="column" colspan="2">数量是否正确</td>
            <td class="value" colspan="2">
              __是&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;__否
            </td>
          </tr>
          <tr>
            <td class="column" colspan="2">货物是否完好</td>
            <td class="value" colspan="2">
              __是&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;__否
            </td>
            <td class="column" colspan="2">物品是否接收</td>
            <td class="value" colspan="2">
              __是&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;__否
            </td>
          </tr>
          <tr>
            <td class="column" colspan="2">接收人/日期</td>
            <td class="value" colspan="2"></td>
            <td class="column" colspan="2">配送人/日期</td>
            <td class="value" colspan="2"></td>
          </tr> -->
          <tr>
            <td class="value" colspan="8" style="text-align: left">备注：</td>
          </tr>
        </table>

        <br />
        <div style="line-height: 30px; color: #333333;">
          <div>*此记录基本信息栏为商家电子信息录入记录。</div>
          <div>
            注1.此记录由顺猴出行平台生成，如有疑问可联系管理员.
          </div>
          <div>
            &nbsp;&nbsp;&nbsp;2.本单据仅对筛选条件内数据做出统计计算，仅为商家核对之用.
          </div>
        </div>
        <br />
      </div>
    </div>
  </div>
</template>
<script>
import { type } from 'os'

export default {
  data() {
    return {
      queryId: 0,
      datas: [],
      pies: [],
      treeList: [],
      type: 0,
      id: 0,
      document: {},
      refundList: [],
      orders: []
    }
  },
  props: [''],
  watch: {
    queryId: {
      // 监听选中id变化,当为最后一层级选中时下拉框关闭
      handler() {
        if (this.$refs.cascader) {
          var children = this.$refs.cascader.getCheckedNodes()
          if (children.length > 0 && children[0].children.length < 1) {
            // 判断有没有下级
            this.$refs.cascader.dropDownVisible = false // 监听值发生变化就关闭它
          }
        }
      }
    }
  },
  computed: {},
  created() {},
  mounted() {
    this.getTreeList()
  },
  methods: {
    async getDocument() {
      console.log('=====================11111')
      const { data: res } = await this.$http.get(
        `getDocument/${this.type}/${this.id}`
      )
      if (res.code !== '20000') {
        return this.$message.error(res.message)
      }
      console.log('=====================22222')
      console.log(res)
      this.document = res.data
      if (res.data != null) {
        var orders = res.data.xOrders
        if (orders.length >= 1) {
          this.orders = orders.map(item => {
            var name = ''
            item.userList.forEach(i => {
              name += ' ' + i.name
            })
            item.username = name
            return item
          })
        }
        this.refundList = res.data.refundList
      }
      console.log('----===========')
      console.log(this.orders)
      console.log(this.refundList)
    },
    // 打印交接单
    printSendTable() {
      this.$print(this.$refs.print)
    },
    // 查看出货单
    previewSendTable() {
      this.$router.push({
        path: '/orderTable',
        query: { type: 'orderTable' }
      })
    },
    async changeFn() {
      var children = this.$refs.cascader.getCheckedNodes()
      var data = children[0].data
      // eslint-disable-next-line eqeqeq
      if (data.goodsId != null && data.goodsId != undefined) {
        this.type = 1
        this.id = data.goodsId
        // eslint-disable-next-line eqeqeq
      } else if (data.id != null && data.id != undefined) {
        this.type = 2
        this.id = data.id
      } else {
        this.type = 3
        this.id = data.tagId
      }
      await this.getDocument()
    },
    handleSelect(e) {
      console.log('eeee')
      console.log(e)
      var parentPreviousElement = e.target.parentElement.previousElementSibling
      var inputRadio = parentPreviousElement.children[0].children[1]
      inputRadio.click()
    },
    async getTreeList() {
      const { data: res } = await this.$http.get('products/gettree')
      console.log(res)
      if (res.code !== '20000') {
        return this.$message.error(res.message)
      }
      console.log('================-----', res)
      res.data.list.forEach(i => {
        i.tagId = i.goodsId
        i.children.forEach(j => {
          j.tagId = j.id
        })
      })
      this.treeList = res.data.list
    },
    async handleTreeList(value) {
      console.log(value)
      console.log(this.queryId)
      // const { data: res } = await this.$http.get(
      //   `/tags/order/${value}/${this.grabMark}`
      // )
      // console.log(res)
      // // eslint-disable-next-line eqeqeq
      // if (res.code == '20000') {
      //   this.total = res.data.total
      //   this.orderlist = res.data.list
      // }
    }
  }
}
</script>

<style scoped>
.button_group {
  margin-top: 30px;
  margin-right: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.print {
  width: 90%;
  margin: 0 auto;
  max-width: 1280px;
}
.table {
  border-collapse: collapse;
  border-spacing: 0;
  background-color: transparent;
  display: table;
  width: 100%;
  max-width: 100%;
  width: 800px;
  margin: 0 auto;
}
.table td {
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  font-family: 'Arial Normal', 'Arial';
  color: #333333;
  padding: 8px 12px;
}
.table-bordered {
  border: 1px solid #ddd;
}
.column {
  width: 30px;
  height: 30px;
  border: 1px solid #333;
  background: #f1f1f1;
}
.value {
  width: 70px;
  height: 30px;
  border: 1px solid #333;
}
</style>
