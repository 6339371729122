<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商家管理</el-breadcrumb-item>
      <el-breadcrumb-item>我的页面</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="数据总览可视化" name="1">
          <reports></reports>
          <refundReports style="margin-top:10px;"></refundReports>
        </el-tab-pane>
        <el-tab-pane label="详细数据可视化" name="2">
          <orderDetail></orderDetail
        ></el-tab-pane>
        <el-tab-pane label="订单数据统计" name="3">
          <document></document
        ></el-tab-pane>
        <!-- <el-tab-pane label="定时任务补偿" name="4">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-input
                placeholder="请输入用户名进行搜索"
                v-model="queryinfo.query"
                clearable
                size="small"
                @clear="getGoodList()"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @mouseover.native="mouseOver()"
                  @mouseout.native="mouseLeave()"
                  @click="getGoodList()"
                ></el-button>
              </el-input>
            </el-col>
          </el-row>
          <el-row>
            <br />
          </el-row>
          <el-table
            :data="goodsList"
            border
            stripe
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column type="index" label="#"></el-table-column>
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-form label-position="left" inline class="demo-table-expand">
                  <el-form-item label="创建时间">
                    <span>{{ props.row.createTime }}</span>
                  </el-form-item>
                  <br />
                  <el-form-item label="更新时间">
                    <span>{{ props.row.updateTime }}</span>
                  </el-form-item>
                </el-form>
              </template>
            </el-table-column>
            <el-table-column
              label="用户名"
              prop="name"
              width="220px"
            ></el-table-column>
            <el-table-column label="头像" prop="avatar" width="66px">
              <template slot-scope="scope">
                <el-avatar :size="'samll'" :src="scope.row.avatar"></el-avatar>
              </template>
            </el-table-column>
            <el-table-column label="出发地" prop="placeIn" width="110px">
              <template slot-scope="scope">
                <el-tag size="medium">{{ scope.row.placeIn }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="目的地" prop="placeOut" width="110px">
              <template slot-scope="scope">
                <el-tag size="medium" type="warning">{{
                  scope.row.placeOut
                }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column width="80px" label="评分" prop="star">
              <template slot-scope="scope">
                <el-badge
                  style="margin-top:1em;"
                  :value="scope.row.star"
                  class="item"
                  type="primary"
                >
                </el-badge>
              </template>
            </el-table-column>
            <el-table-column label="内容" prop="starContent">
              <template slot-scope="scope">
                {{ scope.row.starContent }}
              </template>
            </el-table-column>
            <el-table-column width="80px" label="审核">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.status"
                  @change="usersChanged(scope.row)"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column
              v-if="false"
              fixed="right"
              label="操作"
              width="180px"
            >
              <el-button
                type="primary"
                size="mini"
                icon="fa fa-pencil"
                plain
                circle
                @click="showEditDialog(scope.row.goodsId)"
              ></el-button>
            </el-table-column>
          </el-table>

          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryinfo.pagenum"
            :page-sizes="[5, 10, 20, 50]"
            :page-size="queryinfo.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            background
          >
          </el-pagination>
        </el-tab-pane> -->
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import Reports from '../common/Reports.vue'
import refundReports from '../common/refundReports.vue'
import orderDetail from '../common/orderDetail.vue'
import document from '../common/document.vue'
export default {
  name: 'orderstar',
  data() {
    return {
      // 查询参数对象
      queryinfo: {
        query: '',
        pagenum: 1,
        pagesize: 5
      },
      goodsList: [],
      total: 0,
      visible: false,
      lists: [],
      activeName: '1'
    }
  },
  components: {
    Reports,
    refundReports,
    orderDetail,
    document
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
    },
    mouseOver() {
      this.active = 'background-color: #ccc;'
      //   console.log('鼠标移动事件')
    },
    mouseLeave() {
      this.active = ''
    },
    // 监听开关状态的改变
    async usersChanged(userInfo) {
      const { data: res } = await this.$http.put(
        `stars/${userInfo.id}/state/${userInfo.status}`
      )
      if (res.code !== '20000') {
        userInfo.status = !userInfo.status
        return this.$message.error('更新状态失败!')
      }
      this.$message.success('更新状态成功！')
    },
    // 根据分页获取商品列表的全部数据
    async getGoodList() {
      const { data: res } = await this.$http.get('stars', {
        params: this.queryinfo
      })
      if (res.code !== '20000') {
        this.$message.error(res.message)
        return
      }
      this.$message.success('获取评价列表成功！')
      console.log(res.data)
      this.goodsList = res.data.lists
      this.total = Number(res.data.total)
    },
    handleSizeChange(newSize) {
      this.queryinfo.pagesize = newSize
      this.getGoodList()
    },
    handleCurrentChange(newPage) {
      this.queryinfo.pagenum = newPage
      this.getGoodList()
    },
    handleSelectionChange(val) {
      // var listTemp = []
      // val.forEach(item => {
      //   listTemp.push(Number(item.goodsId))
      // })
      // this.goodsArr = listTemp
    },
    addCoupon() {}
  },
  mounted() {},
  created() {
    // this.getGoodList()
  }
}
</script>

<style lang="less" scoped></style>
