<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 为ECharts准备一个具备大小（宽高）的Dom -->
      <div id="main" style="width: 97%;height:380px;margin-left:4%;"></div>
    </el-card>
  </div>
</template>

<script>
// 1.导入echarts
// import echarts from 'echarts'
import * as echarts from 'echarts'
import _ from 'lodash'
export default {
  name: 'Report',
  data() {
    return {
      maindate: [],
      reportTime: [],
      reportPriceSum: [],
      options: {
        color: ['#2db7f5', '#ff6600', '#808bc6'],
        title: {
          text: '订单详情统计(仅统计已付款订单)',
          subtext: '按日期统计'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#E9EEF3'
            }
          }
        },
        grid: {
          left: '3%',
          right: '5%',
          bottom: '3%',
          top: '24%',
          containLabel: true
        },
        legend: {
          data: ['票数', '交易额']
        },
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: 'none'
            },
            dataView: { readOnly: false },
            magicType: { type: ['line', 'bar'] },
            restore: {},
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          // data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
          data: []
        },
        yAxis: [
          {
            type: 'value',
            name: '交易总票数(张)',
            axisLabel: {
              show: true,
              interval: 'auto',
              formatter: '{value} '
            }
          },
          {
            type: 'value',
            name: '交易额(元)',
            axisLabel: {
              show: true,
              interval: 'auto',
              formatter: '{value} '
            }
          }
        ],
        series: [
          {
            name: '票数',
            type: 'line',
            // data: [10, 11, 13, 11, 12, 12, 9],
            data: [],
            markPoint: {
              data: [
                { type: 'max', name: '最大值' },
                { type: 'min', name: '最小值' }
              ]
            },
            markLine: {
              data: [{ type: 'average', name: '平均值' }]
            }
          },
          {
            name: '交易额',
            type: 'line',
            yAxisIndex: '1',
            // data: [10, 11, 13, 11, 12, 12, 9],
            data: [],
            markPoint: {
              data: [
                { type: 'max', name: '最大值' },
                { type: 'min', name: '最小值' }
              ]
            },
            markLine: {
              data: [{ type: 'average', name: '平均值' }]
            }
          }
          // {
          //   name: '最低气温',
          //   type: 'line',
          //   data: [1, -2, 2, 5, 3, 2, 0],
          //   markPoint: {
          //     data: [{ name: '周最低', value: -2, xAxis: 1, yAxis: -1.5 }]
          //   },
          //   markLine: {
          //     data: [
          //       { type: 'average', name: '平均值' },
          //       [
          //         {
          //           symbol: 'none',
          //           x: '90%',
          //           yAxis: 'max'
          //         },
          //         {
          //           symbol: 'circle',
          //           label: {
          //             position: 'start',
          //             formatter: '最大值'
          //           },
          //           type: 'max',
          //           name: '最高点'
          //         }
          //       ]
          //     ]
          //   }
          // }
        ]
        //   title: {
        //     text: '用户来源'
        //   },
        //   tooltip: {
        //     trigger: 'axis',
        //     axisPointer: {
        //       type: 'cross',
        //       label: {
        //         backgroundColor: '#E9EEF3'
        //       }
        //     }
        //   },
        //   grid: {
        //     left: '3%',
        //     right: '4%',
        //     bottom: '3%',
        //     containLabel: true
        //   },
        //   xAxis: [
        //     {
        //       boundaryGap: false
        //     }
        //   ],
        //   yAxis: [
        //     {
        //       type: 'value'
        //     }
        //   ]
      }
    }
  },
  created() {},
  // 只要执行到mounted 页面上的数据已经渲染完毕
  async mounted() {
    var myChart = echarts.init(document.getElementById('main'))
    // 准备数据和配置项
    const { data: res } = await this.$http.get('getReports')
    console.log(res)
    if (res.code !== '20000') {
      this.$message.error('返回数据失败！')
    }
    res.data.report.forEach(v => {
      // eslint-disable-next-line dot-notation
      // console.log(v.orderCount)
      // eslint-disable-next-line dot-notation
      this.maindate.push(v.orderCount)
      this.reportTime.push(v.orderDate)
      this.reportPriceSum.push(v.orderPriceSum)
    })
    // // 指定图表的配置项和数
    this.options.xAxis.data = this.reportTime
    // console.log(this.orderDate)
    this.options.series[0].data = this.maindate
    this.options.series[1].data = this.reportPriceSum
    // console.log(this.options)
    // const result = _.merge(this.reportTime, this.options.xAxis.data)
    // 使用刚指定的配置项和数据显示图表。
    myChart.setOption(this.options)
  },
  methods: {}
}
</script>

<style lang="less" scoped></style>
