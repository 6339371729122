<template>
  <div>
    <div>
      <span>选择右侧条件，统计相关数据：</span>
      <!-- <el-cascader
        style="margin-left: 12px;"
        size="small"
        placeholder="试试搜索一下"
        :options="treeList"
        v-model="queryId"
        :props="{
          expandTrigger: 'hover',
          value: 'tagId',
          label: 'name',
          emitPath: false,
          checkStrictly: true
        }"
        @change="handleTreeList"
        clearable
        filterable
      ></el-cascader> -->

      <el-cascader
        ref="cascader"
        class="cascader-box"
        v-model="queryId"
        :options="treeList"
        :props="{
          expandTrigger: 'hover',
          value: 'tagId',
          label: 'name',
          emitPath: false,
          checkStrictly: true
        }"
        :show-all-levels="false"
        clearable
        @change="changeFn"
      >
        <template slot-scope="{ node, data }" class="cascader-item">
          <span class="select-item" @click="handleSelect">{{ data.name }}</span>
        </template>
      </el-cascader>
      <div style="display:inline;margin-left:10px;width:200px;">
        <el-radio v-model="status" :label="1">已付款</el-radio>
        <el-radio v-model="status" :label="4">已退款</el-radio>
        <el-radio v-model="status" :label="0">未付款</el-radio>
      </div>
    </div>
    <div id="orderDetail" style=" width: 98%; height:320px;"></div>
    <div style="display:flex;">
      <div id="pie1" style=" width: 50%; height:320px;"></div>
      <div id="pie2" style=" width: 50%; height:320px;"></div>
    </div>
  </div>
</template>

<script>
// import { getBlogContributeCount } from '@/api/index'
import * as echarts from 'echarts'

export default {
  async mounted() {
    this.getTreeList()
    // await this.getStatisyics()
  },
  data() {
    return {
      status: 1,
      queryId: 0,
      datas: [],
      pies: [],
      treeList: [],
      type: 0,
      id: 0
    }
  },
  created() {
    // this.getStatisyics()
  },
  watch: {
    queryId: {
      // 监听选中id变化,当为最后一层级选中时下拉框关闭
      handler() {
        if (this.$refs.cascader) {
          var children = this.$refs.cascader.getCheckedNodes()
          if (children.length > 0 && children[0].children.length < 1) {
            // 判断有没有下级
            this.$refs.cascader.dropDownVisible = false // 监听值发生变化就关闭它
          }
        }
      }
    }
  },
  methods: {
    initDate: function() {
      // getBlogContributeCount().then(response => {
      // console.log('我来啦', response.data.blogContributeCount)
      // eslint-disable-next-line eqeqeq
      // eslint-disable-next-line no-constant-condition
      if (true) {
        // var contributeDate = response.data.contributeDate

        var x = []
        var y = []
        var y1 = []
        this.datas.forEach(item => {
          x.push(item.orderDate)
          y.push({ name: '票数', value: item.orderCount })
          y1.push({ name: '交易额', value: item.orderPriceSum })
        })
        const chart = echarts.init(document.getElementById('orderDetail'))

        const option = {
          legend: {
            top: 20,
            itemGap: 15,
            data: ['票数', '交易额']
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            },
            padding: 10
          },
          xAxis: {
            nameTextStyle: {
              color: '#222',
              fontSize: 14
            },
            axisLabel: {
              margin: 20,
              color: '#595959',
              fontSize: 14
            },
            type: 'category',
            nameLocation: 'center',
            nameGap: 50,
            axisLine: {
              lineStyle: {
                type: 'dashed',
                color: '#ECECEC'
              }
            },
            name: '交易统计',
            data: x
          },
          yAxis: [
            {
              name: '票数',
              nameTextStyle: {
                color: '#222',
                fontSize: 14
              },
              axisLabel: {
                margin: 20,
                color: '#595959',
                fontSize: 14
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed',
                  color: '#ECECEC'
                }
              },
              axisLine: {
                show: false
              },
              type: 'value',
              minInterval: 1,
              nameGap: 20
            },
            {
              name: '交易额',
              nameTextStyle: {
                color: '#222',
                fontSize: 14
              },
              axisLabel: {
                margin: 20,
                color: '#595959',
                fontSize: 14
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed',
                  color: '#ECECEC'
                }
              },
              axisLine: {
                show: false
              },
              type: 'value',
              minInterval: 1,
              nameGap: 20
            }
          ],
          series: [
            {
              type: 'line',
              name: '票数',
              yAxisIndex: 0,
              symbol: 'emptyCircle',
              smooth: 0.3,
              symbolSize: 8,
              itemStyle: {
                borderWidth: 2,
                color: '#17A2B8'
              },
              emphasis: {
                itemStyle: {
                  color: '#17A2B8',
                  borderColor: '#fff'
                }
              },
              data: y
            },
            {
              type: 'bar',
              name: '交易额',
              yAxisIndex: 1,
              showBackground: true,
              barWidth: '20%',
              itemStyle: {
                color: '#FF7656'
              },

              data: y1
            }
          ]
        }
        chart.setOption(option)
      }
      // })
    },
    initPie: function() {
      var y = []
      if (this.pies.length > 0) {
        var total = this.pies[0].totalCount
        var payCount = this.pies[0].payCount
        var unPayCount = this.pies[0].unPayCount
        var refundCount = this.pies[0].refundCount
        y.push({ name: '已付款', value: payCount })
        y.push({ name: '未付款', value: unPayCount })
        y.push({ name: '已退款', value: refundCount })
      }

      const chart = echarts.init(document.getElementById('pie1'))

      const option = {
        title: {
          top: 0,
          text: '付款情况占比',
          left: 'center',
          textStyle: {
            color: '#000'
          }
        },
        backgroundColor: '#fff',
        grid: {
          top: 0,
          bottom: 0
        },
        legend: {
          orient: 'vertical',
          left: '50',
          top: 'center',
          data: ['已付款', '未付款', '已退款'],
          textStyle: {
            color: '#66dbfc',
            fontSize: 14
          },
          itemWidth: 18,
          itemHeight: 18,
          itemGap: 20
        },
        series: [
          {
            name: '测试集',
            type: 'pie',
            color: ['#50A051', '#CECED3', '#FFB658'],
            selectedMode: 'single',
            radius: [0, '65%'],
            center: ['60%', '50%'],
            labelLine: {
              normal: {
                show: false
              }
            },
            hoverAnimation: false,
            data: y,
            label: {
              normal: {
                formatter: '{a|{d}%}' + '\n' + '\n' + '{b|{c}}' + '{b|单}',
                backgroundColor: 'rgba(255, 147, 38, 0)',
                borderColor: 'transparent',
                borderRadius: 4,
                rich: {
                  a: {
                    color: '#000',
                    fontSize: 14
                  },
                  b: {
                    color: '#000',
                    fontSize: 14
                  }
                }
              }
            }
          }
        ]
      }
      chart.setOption(option)
    },
    initPie2: function() {
      var y = []
      if (this.pies.length > 0) {
        var total = this.pies[0].totalCount
        var orderPayCount = this.pies[0].orderPayCount
        var grabPayCount = this.pies[0].grabPayCount
        y.push({ name: '正常方式', value: orderPayCount })
        y.push({ name: '抢票方式', value: grabPayCount })
      }

      const chart = echarts.init(document.getElementById('pie2'))

      const option = {
        title: {
          top: 0,
          text: '付款方式占比',
          left: 'center',
          textStyle: {
            color: '#000'
          }
        },
        backgroundColor: '#fff',
        grid: {
          top: 0,
          bottom: 0
        },
        legend: {
          orient: 'vertical',
          left: '50',
          top: 'center',
          data: ['正常方式', '抢票方式'],
          textStyle: {
            color: '#66dbfc',
            fontSize: 14
          },
          itemWidth: 18,
          itemHeight: 18,
          itemGap: 20
        },
        series: [
          {
            name: '测试集',
            type: 'pie',
            color: ['#A658FF', '#FF5899'],
            selectedMode: 'single',
            radius: [0, '65%'],
            center: ['60%', '50%'],
            labelLine: {
              normal: {
                show: false
              }
            },
            hoverAnimation: false,
            data: y,
            label: {
              normal: {
                formatter: '{a|{d}%}' + '\n' + '\n' + '{b|{c}}' + '{b|单}',
                backgroundColor: 'rgba(255, 147, 38, 0)',
                borderColor: 'transparent',
                borderRadius: 4,
                rich: {
                  a: {
                    color: '#000',
                    fontSize: 14
                  },
                  b: {
                    color: '#000',
                    fontSize: 14
                  }
                }
              }
            }
          }
        ]
      }
      chart.setOption(option)
    },
    async getStatisyics() {
      const { data: res } = await this.$http.get(
        `getReports/${this.type}/${this.id}/${this.status}`
      )
      if (res.code !== '20000') {
        return this.$message.error(res.message)
      }
      this.datas = res.data.report
      this.pies = res.data.pies
    },
    async changeFn() {
      var children = this.$refs.cascader.getCheckedNodes()
      var data = children[0].data
      // eslint-disable-next-line eqeqeq
      if (data.goodsId != null && data.goodsId != undefined) {
        this.type = 1
        this.id = data.goodsId
        // eslint-disable-next-line eqeqeq
      } else if (data.id != null && data.id != undefined) {
        this.type = 2
        this.id = data.id
      } else {
        this.type = 3
        this.id = data.tagId
      }
      await this.getStatisyics()
      this.initDate()
      this.initPie()
      this.initPie2()
    },
    handleSelect(e) {
      console.log('eeee')
      console.log(e)
      var parentPreviousElement = e.target.parentElement.previousElementSibling
      var inputRadio = parentPreviousElement.children[0].children[1]
      inputRadio.click()
    },
    async getTreeList() {
      const { data: res } = await this.$http.get('products/gettree')
      console.log(res)
      if (res.code !== '20000') {
        return this.$message.error(res.message)
      }
      console.log('================-----', res)
      res.data.list.forEach(i => {
        i.tagId = i.goodsId
        i.children.forEach(j => {
          j.tagId = j.id
        })
      })
      this.treeList = res.data.list
    },
    async handleTreeList(value) {
      console.log(value)
      console.log(this.queryId)
      // const { data: res } = await this.$http.get(
      //   `/tags/order/${value}/${this.grabMark}`
      // )
      // console.log(res)
      // // eslint-disable-next-line eqeqeq
      // if (res.code == '20000') {
      //   this.total = res.data.total
      //   this.orderlist = res.data.list
      // }
    }
  }
}
</script>
